import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './Modal.css';
import { Modal } from "antd";
import React from "react";
import { BsFillExclamationTriangleFill } from  'react-icons/bs'

const ModalAlert = ({ visible, onOk, onCancel, title }) => {
  return (
    <>
      <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel} cancelButtonProps={{ style: { display: 'none' } }} closable={false}>
        <div className="content_modal">
          <h3>
            Os dados estão sendo enviados. 
            <br></br> 
            <strong>Não saia desta tela!</strong>
            <br></br> 
            Aguarde até que o processo termine...
          </h3> 
          <span className='icon'>
            <BsFillExclamationTriangleFill />
          </span>
        </div>
      </Modal>
    </>
  );
};

export default ModalAlert;

// import React, { Component } from 'react'
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import { Modal } from "antd";
// import './Modal.css';

// class ModalAlert extends Component {
//     render() {
//         return (
//             <div>
//                 <Modal open={this.props.visible} onCancel={this.props.close} onOk={this.props.close}>
//                   <div className="content_modal">
//                     <h3>
//                       Os dados estão sendo enviados. 
//                       <br></br> 
//                       Aguarde até que o processo termine..
//                     </h3> 
//                     <i className='fas fa-exclamation-circle'></i>
//                   </div>
//                 </Modal>
//             </div>
//         )
//     }  
// }

// export default ModalAlert;