import React from 'react';
import {Input,Row,Col,List,Tag,Card, Button} from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

class SelectProduct extends React.Component {
  state = {
    search:"",
    typing:false,
    typingTimeout:0,

    products_search:[],
    products_selected:[]
  };
  confirmSelection(){
    this.props.onConfirm(this.state.products_selected);
  }
  changeSearch(event){
    if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout);
     }
 
     this.setState({
        search: event.target.value,
        typing: false,
        typingTimeout: setTimeout(()=>{
            this.search(this.state.search);
        }, 500)
     });
  }
  search(search){
    if(!search || search === "")return;
    this.setState({leading:true});
    window.BX24.callMethod('crm.product.list',
    {
        order: {"NAME": "ASC"},
        filter: {"NAME": "%"+search+"%"},
        select: ["ID", "NAME", "CURRENCY_ID", "PRICE", "PROPERTY_*"]
    }, 
    (result)=>{
        console.log(result.data());
        this.setState({loading:false,products_search:result.data()});
    });
  }
  addProduct(prod){
    if(this.state.products_selected.filter((p)=>{return p.ID === prod.ID}).length > 0){
        return;
    }
    let p = Object.assign({},prod);
    p.uid = '_' + Math.random().toString(36).substr(2, 9);

    let products_selected = Object.assign([],this.state.products_selected);
    products_selected.push(p);
    this.setState({products_selected});
    if(this.props.onChange)
    this.props.onChange(products_selected);
  }
  removeProduct(p){
    let products_selected = Object.assign([],this.state.products_selected);
    products_selected = products_selected.filter(prod=> prod.uid !== p.uid );
    this.setState({products_selected});
    if(this.props.onChange)
    this.props.onChange(products_selected);
  }
  render() {   
    return (
    <Card size="small">
        <Row gutter={8}>
        <Col span={16} style={{height:"200px", padding:5}}>
            <Input placeholder="Busque Pelo nome do produto" value={this.state.search} onChange={this.changeSearch.bind(this)}/>

            <List
                locale={ptBR}
                style={{maxHeight:170,overflow:"auto"}}
                loading={this.state.loading}
                size="small"
                itemLayout="horizontal"
                dataSource={this.state.products_search}
                renderItem={item => (
                <List.Item
                    actions={[<Button onClick={this.addProduct.bind(this,item)} key="list-loadmore-add">adicionar</Button>]}
                >
                    <List.Item.Meta
                    title={item.NAME}
                    />
                </List.Item>
                )}
            />
        </Col>
        <Col span={8} style={{height:"200px",padding:5}}>
            <div style={{height:200,overflow:"auto",background:"#f5f5f5",border:"1px solid #e2e2e2",padding:3}}>
                <div>Selecionado({this.state.products_selected.length})</div>
                <div>
                    {
                        this.state.products_selected.map((p)=>(
                            <Tag onClose={this.removeProduct.bind(this,p)} key={p.uid} closable={true}>{p.NAME}</Tag>
                        ))
                    }
                </div>
            </div>
        </Col>
        </Row>
    </Card>
    );
  }
}

export default SelectProduct;