import React from 'react';
import {Input} from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import { isNumber } from 'util';

class InputBRL extends React.Component {
  state = {
    value_number:0,
    value_string:"00.00"
  };
  componentDidMount(){
    let value_number = Number(this.props.value);
    let value_string = this.formatMoney(value_number);
    this.setState({value_string});
  }
  componentWillReceiveProps(props){
    let value_number = Number(props.value);
    let value_string = this.formatMoney(value_number);
    this.setState({value_string});
  }
  onChange(e){
    let value_string = e.target.value;
    value_string = this.formatMoney(value_string);
    this.setState({value_string});

    let value_number = value_string.replace(".", "").replace(",",".");

    value_number = Number(value_number);

    this.props.onChange({target:{value: value_number}});
  }
  formatMoney(v){
    if(isNumber(v)){
        v = v.toFixed(2);
        if(v.includes(".") &&  v.split('.')[1].length === 1)v = v+"0";
        if(!v.includes(".")) v = v+ ".00";
    }
    v = v.replace(/\D/g,'');
    v = (v/100).toFixed(2) + '';
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    return v
  }
  render(){
      return (
        <Input onClick={(e)=>{e.target.select()}}  disabled={this.props.disabled} value={this.state.value_string} style={this.props.style} onChange={this.onChange.bind(this)} prefix={this.props.prefix} suffix={this.props.suffix} />
      )
  }
}
export default InputBRL;