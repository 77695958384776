import React from 'react';
class AppLayout extends React.Component {
  state = {};
  render() {   
    return (
      <div style={{minHeight:630}}>
      {this.props.children}
      </div>
    );
  }
}

export default AppLayout;