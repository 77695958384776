import React from 'react';
import {Bitrix24Context} from '../contexts/Bitrix24Context';


class Bitrix24Provider extends React.Component {
  state = {
    user:{},
    loadding: true,
    onbitrix:false,
    placement:null,
    isAdmin: false,

    editDeal: this.editDeal.bind(this),
    getDealFields: this.getDealFields,
    getProductFields: this.getProductFields,
    listProducts:this.listProducts,
    getDeal: this.getDeal,
    setProductRowsDeal: this.setProductRowsDeal.bind(this),
    getProductRowsDeal: this.getProductRowsDeal.bind(this),

    getAllUsers: this.getAllUsers.bind(this),
    addQuote: this.addQuote.bind(this),
    setProductRowsQuote: this.setProductRowsQuote.bind(this),
    getProductRowsQuote: this.getProductRowsQuote.bind(this),
    listQuotesFromDeal: this.listQuotesFromDeal.bind(this),
    editQuote: this.editQuote.bind(this)
  }
  
  componentDidMount(){
    try{
      window.BX24.init(()=>{
        window.BX24.callMethod('user.current', {},async (res)=>{
          let user = res.data();
          //seto o user aqui e uso no Bitrix24ListsProvider
          this.setState({
            loadding: false,
            onbitrix:true,
            user: user,
            placement: window.BX24.placement.info(),
            isAdmin: window.BX24.isAdmin()
          });
          window.BX24.fitWindow();
        });
      });
    }catch(e){
      console.log(e);
      this.setState({onbitrix:false, loadding:false});
    }
  }
  listProducts(filter, select = []){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.product.list', {
        filter:filter,
        select:select
      },(result)=>{
        if(result.error()){
          reject(result.error());
        }
        resolve(result.data());
      });
    });
  }
  listQuotesFromDeal(deal_id){
    return new Promise((resolve,reject)=>{
      window.BX24.callMethod('crm.quote.list',{
        order: { "CLOSEDATE": "DESC" },
        filter:{"=DEAL_ID": deal_id}
      }, (result)=>{
        if(result.error()){
          reject(result.error());
        }else{
          resolve(result.data());
        }
      });
    })
  }
  getDeal(id){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.deal.get', {id:id},(result)=>{
        if(result.error()){
          reject(result.error());
        }else{
          resolve(result.data());
        }
      });
    });
  }
  getDealFields(){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.deal.fields', {},(result)=>{
        if(result.error()){
          reject(result.error());
        }
        resolve(result.data());
      });
    });
  }
  getProductFields(){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.product.fields', {},(result)=>{
        if(result.error()){
          reject(result.error());
        }
        resolve(result.data());
      });
    });
  }
  getQuote(id){
    return new Promise(async (resolve, reject)=>{
      try{
        let quote = await this.getOnlyQuote(id);
        let product_rows = await this.getProductRowsQuote(id);
        quote.product_rows = product_rows;
        let products = [];
        for(let i = 0;i<product_rows.length;i++){
          let p = await this.getProduct(product_rows[i].PRODUCT_ID);
          p.QUANTITY = product_rows[i].QUANTITY;
          p.PRICE = product_rows[i].PRICE
          products.push(p)
        }
        quote.products = products;
        resolve(quote);   
      }catch(e){
        reject(e);
      }
    })
  }
  getProduct(id){
    return new Promise(async (resolve, reject)=>{
      window.BX24.callMethod('crm.product.get', 
      { 
        id:id,
        select:["ID", "NAME", "CURRENCY_ID", "PRICE",...Object.keys(this.state.product_values).map((k)=>{return this.state.product_values[k]})]
      },(result)=>{
        if(result.error()){
          reject(result.error());
        }else{
          let produtos = result.data();
          resolve(produtos);
        }
      });
    });
  }
  getOnlyQuote(id){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.quote.get', {id:id,select:["ID","TITLE","STATUS_ID","OPENED","ASSIGNED_BY_ID","CURRENCY_ID","OPPORTUNITY"]},(result)=>{
        if(result.error()){
          reject(result.error());
        }else{
          let quote = result.data();
          resolve(quote);
        }
      });
    });
  }
  getProductRowsQuote(id){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.quote.productrows.get', {id:id},(result)=>{
        if(result.error()){
          reject(result.error());
        }else{
          let product_rows = result.data();
          resolve(product_rows);
        }
      });
    })
  }
  getProductRowsDeal(id){
    return new Promise((resolve, reject)=>{
      window.BX24.callMethod('crm.deal.productrows.get', {id:id},(result)=>{
        if(result.error()){
          reject(result.error());
        }
        let product_rows = result.data();
        resolve(product_rows);
      });
    })
  }
  getProductConfig(){
    return new  Promise((resolve,reject)=>{
      let product_values = Object.assign({},this.state.product_values);
      Object.keys(product_values).forEach((key)=>{
        product_values[key] = window.BX24.appOption.get(key);
      });
      this.setState({product_values},resolve);
    })
  }
  addQuote(quote){
		return new Promise((resolve, reject)=>{
			window.BX24.callMethod('crm.quote.add',{fields:quote}, (result)=>{
				if(result.error()){
					reject(result.error());
				}else{
					resolve(result.data());
				}
			});
		});
  }
  editQuote(id, quote){
		return new Promise((resolve, reject)=>{
			window.BX24.callMethod('crm.quote.update',{id:id, fields:quote}, (result)=>{
				if(result.error()){
					reject(result.error());
				}else{
					resolve(result.data());
				}
			});
		});
  }
  editDeal(id, deal){
		return new Promise((resolve, reject)=>{
			window.BX24.callMethod('crm.deal.update',{id:id, fields:deal}, (result)=>{
				if(result.error()){
					reject(result.error());
				}else{
					resolve(result.data());
				}
			});
		});
  }
  setProductRowsQuote(quote_id, product_rows){
		return new Promise((resolve, reject)=>{
			window.BX24.callMethod("crm.quote.productrows.set", { id: quote_id, rows:product_rows }, (result)=> {
				if(result.error()){
					reject(result.error());
				}else{
					resolve(result.data());
				}
			});
		});
  }
  setProductRowsDeal(deal_id, product_rows){
		return new Promise((resolve, reject)=>{
			window.BX24.callMethod("crm.deal.productrows.set", { id: deal_id, rows:product_rows}, (result)=> {
				if(result.error()){
					console.log(result.error());
					reject(result.error());
				}else{
					resolve(result.data());
				}
			});
		});
  }
  
  getAllUsers(){
    return new Promise((resolve, reject)=>{
      let result_total = [];
      window.BX24.callMethod('user.get',{filter:{ACTIVE:true}},(result)=>{
        if(result.error()){
          reject(result.error());
        }else{
          result_total = [...result_total, ...result.data()];
          if (result.more()){
            result.next();
          }else{
            resolve(result_total);
          }
        }
      });
    });
  }
  render(){
    if(this.state.loadding){
      return <div>Carregando Informações do Bitrix24</div>
    }else if(!this.state.onbitrix){
      return <div>Aplicativo pode ser aberto apenas no Bitrix24</div>
    }
    return (
      <Bitrix24Context.Provider value={this.state}>
        {this.props.children}
      </Bitrix24Context.Provider>
    )
  }
}

export default Bitrix24Provider;
