import React from 'react';
import {Input,Row,Col,List,Tag,Card, Button} from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import {Bitrix24Consumer} from '../contexts/Bitrix24Context';
import { Bitrix24ListsConsumer } from '../contexts/Bitrix24ListsContext';
import {price_list_map} from '../config/map';

class SelectProductPrice extends React.Component {
  state = {
    search:"",
    typing:false,
    typingTimeout:0,

    products_search:[],
    products_selected:[]
  };
  confirmSelection(){
    this.props.onConfirm(this.state.products_selected);
  }
  changeSearch(event){
    if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout);
     }
 
     this.setState({
        search: event.target.value,
        typing: false,
        typingTimeout: setTimeout(()=>{
            this.search(this.state.search);
        }, 500)
     });
  }
  async search(search){
    if(!search || search === "")return;
    this.setState({leading:true});
    let filter = {}

    filter[price_list_map.UNITY] = this.props.unity;
    filter.NAME = "%"+search+"%";
    let prices = await this.props.b24_lists.getListElems(price_list_map, filter);
    console.log(prices);
    this.setState({loading:false,products_search:prices});
  
  }
  addProduct(prod){
    let p = Object.assign({},prod);
    p.uid = '_' + Math.random().toString(36).substr(2, 9);
    p.QTD = 1;
    p.TOTAL = p.PRICE*p.QTD;
    p.DISCOUNT_SUM = 0;
    p.DISCOUNT_RATE =0;

    let products_selected = Object.assign([],this.state.products_selected);
    products_selected.push(p);
    this.setState({products_selected});
  }
  removeProduct(p){
    let products_selected = Object.assign([],this.state.products_selected);
    products_selected = products_selected.filter(prod=> prod.uid !== p.uid );
    this.setState({products_selected});
  }
  render() {   
    return (
    <Card size="small">
        <Row gutter={8}>
        <Col span={16} style={{height:"400px", padding:5}}>
            <Input placeholder="Nome do produto..." value={this.state.search} onChange={this.changeSearch.bind(this)}/>

            <List
                locale={ptBR}
                style={{maxHeight:350,overflow:"auto"}}
                loading={this.state.loading}
                size="small"
                itemLayout="horizontal"
                dataSource={this.state.products_search}
                renderItem={item => (
                <List.Item  actions={[<Button onClick={this.addProduct.bind(this,item)} key="list-loadmore-add">adicionar</Button>]}>
                    <List.Item.Meta
                    title={item.NAME}
                    />
                </List.Item>
                )}
            />
        </Col>
        <Col span={8} style={{height:"400px",padding:5}}>
            <div style={{height:"100%",background:"#f5f5f5",border:"1px solid #e2e2e2",padding:3}}>
                <div>Selecionado({this.state.products_selected.length})</div>
                <div>
                    {
                        this.state.products_selected.map((p)=>(
                            <Tag onClose={this.removeProduct.bind(this,p)} key={p.uid} closable={true}>{p.NAME}</Tag>
                        ))
                    }
                </div>
            </div>
        </Col>
        </Row>
        <Row>
            <Button type="primary" style={{float:"right", marginLeft:10}} disabled={this.state.products_selected.length === 0} onClick={this.confirmSelection.bind(this)}>Selecionar</Button>
            <Button style={{float:"right", marginLeft:10}} onClick={()=>this.props.onConfirm([])}>Fechar</Button>
        </Row>
      
    </Card>
    );
  }
}

export default Bitrix24Consumer(Bitrix24ListsConsumer(SelectProductPrice));