import React from "react";
import { Bitrix24ListsContext } from "../contexts/Bitrix24ListsContext";
import { Bitrix24Consumer } from "../contexts/Bitrix24Context";
import { parcelas_agrupadas_list_map } from "../config/map";
import bitrix from "./Bitrix";

class Bitrix24ListProvider extends React.Component {
  state = {
    loading: false,
    my_table: null,
    getListElems: this.getListElems.bind(this),
    getListFields: this.getListFields.bind(this),
    saveListElem: this.saveListElem.bind(this),
    removeListElem: this.removeListElem.bind(this),
  };
  async componentDidMount() {}
  getListElems(list_map, filter) {
      return new Promise((resolve, reject) => {
        var params = {
          IBLOCK_TYPE_ID: "lists",
          IBLOCK_ID: list_map.IBLOCK_ID,
          FILTER: filter,
        };
  
        window.BX24.callMethod("lists.element.get", params, async (result) => {
          if (result.error()) reject(result.error());
    
          let elems;
          let elems2;
          
          console.log(result.answer.next);
          if(result.answer.next){
            let start = result.answer.next;
            elems2 = await this.getListRemainderElems(list_map,filter,start);
            console.log(elems2);
          }
          if(elems2 !== undefined){
            elems = result.data();
            elems = elems.concat(elems2);
            console.log(elems); 
          }

          if(elems !== undefined){
            elems = elems;
          }else{
            elems = result.data();
          }
          
          elems = elems.map((e) => {
            let props = Object.keys(list_map);
            for (let i = 0; i < props.length; i++) {
              let p = props[i];
              if (e[list_map[p]]) {
                if (e[list_map[p]] && Object.keys(e[list_map[p]]).length > 1) {
                  e[p] = [];
                  Object.keys(e[list_map[p]]).forEach((key) => {
                    e[p].push(e[list_map[p]][key]);
                  });
                } else {
                  e[p] = e[list_map[p]][Object.keys(e[list_map[p]])[0]];
                }
                  delete e[list_map[p]];
              }
            }
            return e;
          });
        console.log(elems);
        resolve(elems);
      });
    });
  }

  // getListRemainderElems(list_map, filter) {
  //   return new Promise((resolve, reject) => {
  //     var params = {
  //       IBLOCK_TYPE_ID: "lists",
  //       IBLOCK_ID: list_map.IBLOCK_ID,
  //       FILTER: filter,
  //       start: 50,
  //     };
  //     window.BX24.callMethod("lists.element.get", params, function(result){
  //       if (result.error()) {
  //         reject(result.error());
  //       }
  //       console.log(result.data());
  //       resolve(result.data());
  //       });
  //   });
  // }

  async getListRemainderElems(list_map, filter,start){
    var params = {
      IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: list_map.IBLOCK_ID,
        FILTER: filter,
        start: start,
    };
    const response = await bitrix.post(
      'lists.element.get',
      params
    );

    if (!response.data.result) {
      return false;
    } else {
      console.log(response.data.result);
      return response.data.result;
    }
  }

  getListFields(list_map) {
    return new Promise((resolve, reject) => {
      var params = {
        IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: list_map.IBLOCK_ID,
      };
      window.BX24.callMethod("lists.field.get", params, (result) => {
        if (result.error()) {
          reject(result.error());
        }
        resolve(result.data());
      });
    });
  }
  saveListElem(list_map, elem) {
    if (elem.ELEMENT_ID) {
      return this.updateListELem(list_map, elem);
    }
    return this.addListElem(list_map, elem);
  }
  addListElem(list_map, elem) {
    return new Promise((resolve, reject) => {
      var params = {
        IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: list_map.IBLOCK_ID,
        ELEMENT_CODE: elem.ELEMENT_CODE,
        FIELDS: {},
      };
      //unico campo padrão
      params.FIELDS.NAME = elem.NAME;

      delete elem[list_map.IBLOCK_ID];
      Object.keys(list_map).forEach((key) => {
        params.FIELDS[list_map[key]] = elem[key];
      });

      console.log(params);
      window.BX24.callMethod("lists.element.add", params, (result) => {
        if (result.error()) reject(result.error());

        resolve(result.data());
      });
    });
  }
  updateListELem(list_map, elem) {
    return new Promise((resolve, reject) => {
      var params = {
        IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: list_map.IBLOCK_ID,
        ELEMENT_ID: elem.ELEMENT_ID,
        FIELDS: {},
      };
      delete elem[list_map.IBLOCK_ID];
      params.FIELDS.NAME = elem.NAME;
      Object.keys(list_map).forEach((key) => {
        params.FIELDS[list_map[key]] = elem[key];
      });

      window.BX24.callMethod("lists.element.update", params, (result) => {
        if (result.error()) reject(result.error());

        resolve(result.data());
      });
    });
  }
  removeListElem(list_map, element_id) {
    return new Promise((resolve, reject) => {
      var params = {
        IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: list_map.IBLOCK_ID,
        ELEMENT_ID: element_id,
      };
      window.BX24.callMethod("lists.element.delete", params, (result) => {
        if (result.error()) {
          reject(result.error());
        }
        resolve(result.data());
      });
    });
  }
  hashCode(s) {
    for (var i = 0, h = 0; i < s.length; i++)
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    return h;
  }
  render() {
    return (
      <Bitrix24ListsContext.Provider value={this.state}>
        {this.props.children}
      </Bitrix24ListsContext.Provider>
    );
  }
}

export default Bitrix24Consumer(Bitrix24ListProvider);
