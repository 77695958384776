import React from 'react';
import { Link } from 'react-router-dom';
import {Bitrix24Consumer} from '../contexts/Bitrix24Context';
import {message, Menu} from 'antd';
import {HOMEPAGE} from '../config/urls';
import PriceConfig from './PriceConfig';

class Home extends React.Component {
  componentDidMount(){
    window.BX24.callMethod("placement.get",{},(result)=>{
      console.log(result);
    });
  }
  installPlacement(){
    window.BX24.callMethod("placement.bind",
    {
      PLACEMENT:"CRM_DEAL_DETAIL_TAB",
      HANDLER: encodeURI(HOMEPAGE+"/placement_deal"),
      TITLE:"Estabelecer - beta - nbw",
      DESCRIPTION:"Composição de Orcçamentos"
    },(result)=>{
      console.log(result.data());
      message.info("Tela adicionada nos detalhes do negócio");
    })
  }
  uninstallPlacement(){
    window.BX24.callMethod("placement.unbind",{PLACEMENT:"CRM_DEAL_DETAIL_TAB"},(result)=>{
      console.log(result.data());
      message.info("Placement removido");
    })
  }
  render(){
    return(
     <div style={{minHeight:1500, background:"#eef2f4"}}>
      <Menu mode="horizontal">
        <Menu.Item key="placement_deal">
          <Link to="/placement_deal">Testar Placement</Link>
        </Menu.Item>
        <Menu.Item key="Install">
          <i onClick={this.installPlacement.bind(this)}>Instalar Placement</i>
        </Menu.Item>
        <Menu.Item key="Uninstall">
          <i onClick={this.uninstallPlacement.bind(this)}>Remover Placement</i>
        </Menu.Item>
      </Menu>
      <div style={{padding:3}}>
        <PriceConfig/>
      </div>
    </div>
    )
  }
}

export default Bitrix24Consumer(Home);
