import React from 'react';
import {Bitrix24Consumer} from '../contexts/Bitrix24Context';
import {Bitrix24ListsConsumer} from '../contexts/Bitrix24ListsContext';
import {Card, Select, Button, Row, Divider,Col, Progress, message, Modal} from 'antd';
import SelectProduct from '../components/SelectProduct';
import InputBRL from '../components/InputBRL';
import { jsxClosingElement } from '@babel/types';
import {price_list_map, units_list_map} from '../config/map';

const { Option } = Select;

class PriceConfig extends React.Component {
    state = {
        units: [],
        selected_units:[],
        selected_products:[],

		showPricesForm:false,
		loading_saved_prices:false,
		products_with_price:[],
		
		saving:false,
		save_progress:0,
    }
    async componentDidMount(){
        let units = await this.props.b24_lists.getListElems(units_list_map, {});
        let units_options = units.map((u)=>{return <Option key={u.ID}>{u.NAME}</Option>});
        this.setState({units, units_options});

        window.BX24.fitWindow();
    }
    async renderFormProducts(){
		this.setState({loading_saved_prices:true});
        let selected_products = this.state.selected_products;
        let selected_units = this.state.selected_units;

		let selected_products_ids = selected_products.map( p => p.ID);
		let selected_units_ids = selected_units;

		let search_filter = {};
		search_filter[price_list_map.PRODUCT_ID] = selected_products_ids;
		search_filter[price_list_map.UNITY] = selected_units_ids;

		let saved_price_elems = await this.props.b24_lists.getListElems(price_list_map, search_filter);

        selected_products.forEach(p => {
            let PRICES = [];
            for(let k = 0; k < selected_units.length; k++){
								let saved_price = saved_price_elems.filter(e => e.PRODUCT_ID === p.ID && e.UNITY === selected_units[k]);

								if(saved_price.length === 0){
									saved_price = null;
								}else{
									saved_price = saved_price[0];
								}

                let price = {
										"UNITY":selected_units[k],
										"ELEMENT_ID": saved_price ? saved_price.ID : null,
                    "UNITY_NAME":this.state.units.filter((u)=>{return u.ID === selected_units[k]})[0].NAME,
                    "PRICE":saved_price ? saved_price.PRICE : 0
                }
                PRICES.push(price);
            }
            p.PRICES = PRICES;
        });
        this.setState({products_with_price:selected_products, showPricesForm:true, loading_saved_prices:false},()=>{
			window.BX24.fitWindow();
		});

    }
    onChangeSelectProducts(selected_products){
        this.setState({selected_products});
    }
    onChangeSelectUnits(selected_units){
		this.setState({selected_units});
	}
	onChangeProductValue(product_index, price_index, e){
		let products_with_price = this.state.products_with_price;
		products_with_price[product_index].PRICES[price_index].PRICE = e.target.value;
		this.setState({products_with_price});
	}
	async savePrices(){
		try{
		let products_with_price = this.state.products_with_price;
		let list_price_rows = [];

		products_with_price.forEach(p => {
			p.PRICES.forEach(price => {
				let row = {
					ELEMENT_ID:price.ELEMENT_ID,
					ELEMENT_CODE: price.UNITY+"-"+p.ID,
					PRODUCT_ID: p.ID,
					NAME: p.NAME,
					UNITY:price.UNITY,
					PRICE: price.PRICE
				}
				list_price_rows.push(row);
			});
		});
		console.log(list_price_rows);
		this.setState({saving:true, save_progress:0});
		let total = list_price_rows.length;
		for(let i = 0; i < total;i++){
			let row = list_price_rows[i];
			await this.props.b24_lists.saveListElem(price_list_map, row);
			this.setState({save_progress: ((100*(i+1))/total).toFixed(0)});
		}
		this.setState({saving:false, save_progress:100});
		this.setState({saving:false, save_progress:100});
			Modal.success({
				content: 'Salvo',
			});	
		}catch(ex){
			this.setState({saving:false, save_progress:100});
			Modal.error({
				content: 'Algo deu errado',
			});
		}
	}
    render(){
    return(
     <Card style={{marginTop:5}} title="Preços dos produtos">
         <div>
            <Row gutter={[12,24]}>
                <Col span={24}>
                    <h3>Configurar os produtos:</h3>
                    <SelectProduct onChange={this.onChangeSelectProducts.bind(this)}/>
                </Col>
            </Row>
            <Row gutter={[12,24]}>
                <Col span={24}>
                    <h3>Para as unidades:</h3>
                    <Select 
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Marque as Unidades"
                        onChange={this.onChangeSelectUnits.bind(this)}
                    >
                        {this.state.units_options}
                    </Select>
                </Col>
            </Row>
            <Row style={{marginTop:30}}>
                <Col span={24}>
                    <Button 
                        disabled={this.state.selected_units.length === 0 || this.state.selected_products.length === 0} 
                        onClick={this.renderFormProducts.bind(this)} 
												type="primary"
												loading={this.state.loading_saved_prices}
											
                    >
                        Configurar
                    </Button>
                </Col>
            </Row>
        </div>
        <Divider/>
        <div style={{border: "1px dashed #e9e9e9",borderRadius: "6px", backgroundColor: "#fafafa", padding:10}}>
            {
                !this.state.showPricesForm 
                ?
                <div className="search-result-list">Lista dos Produtos Selecionados</div>
				:
				<div>
					<Row gutter={20}>
					
						{
							this.state.products_with_price.map((p,i)=>(
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Card title={p.NAME}>
										<div>
											
											<table>
												<tbody>
													{
													p.PRICES.map((price, k)=>(
														<tr>
															<td style={{width:100}}>Preço {price.UNITY_NAME} :</td>
															<td><InputBRL style={{width:"100%"}} onChange={this.onChangeProductValue.bind(this,i, k)} value={price.PRICE} /></td>
														</tr>
													))
													}
												</tbody>
											</table>
										
										</div>
									</Card>
									<Divider/>
								</Col>
							))
						}
							
					</Row>
					{
						this.state.saving &&
						<Progress percent={this.state.save_progress} />
					}	
					<Button 
							disabled={this.state.selected_units.length === 0 || this.state.selected_products.length === 0} 
							onClick={this.savePrices.bind(this)} 
							type="primary"
					>
							Salvar Preços
					</Button>
				</div>
            }
        </div>
     </Card>
    )
  }
}

export default (
    Bitrix24Consumer(
        Bitrix24ListsConsumer(
            PriceConfig
        )
    )
);
