import React from 'react';
import {BrowserRouter as ReactRouter, Route, Switch} from 'react-router-dom';
import Layout from '../components/Layout';
//Views
import home from '../views/Home';
import quote from '../views/Quote';
import notFound from '../views/NotFound';
import {SUBFOLDER} from '../config/urls';

class Router extends React.Component {
  render(){
    return (
      <ReactRouter basename={SUBFOLDER}>
        <Layout>   
          <Switch>
            <Route exact path="/placement_deal" component={quote} />    
            <Route exact path="/home" component={home} />  
            <Route exact path="/" component={home} />    
            <Route component={notFound}/>
          </Switch>       
        </Layout>
      </ReactRouter>
    );
  }
}

export default (Router);
