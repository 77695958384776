const price_list_map = {
  IBLOCK_ID: "29",
  PRODUCT_ID: "PROPERTY_95",
  UNITY: "PROPERTY_97",
  PRICE: "PROPERTY_99",
};
const units_list_map = {
  IBLOCK_ID: "27",
};
const crm_deal_map = {
  UNITY: "UF_CRM_1580774919",
  QTTY_INSTALLMENTS: "UF_CRM_5E2A1B2812B1F",
  PRICE_INSTALLMENT: "UF_CRM_5E2A1B281EEED",
  PONTUAL_INITIAL_VALUE: "UF_CRM_5E2A1B2829C8F",
};
const parcela_list_map = {
  IBLOCK_ID: "71",
  DEAL: "PROPERTY_505",
  DATE: "PROPERTY_507",
  VALUE: "PROPERTY_509",
  COMMENT: "PROPERTY_511",
  PRODUCTS_ID: "PROPERTY_513", //MULTIPLO
  PRODUCTS_NAME: "PROPERTY_515", //MULTIPLO
  CONFIG: "PROPERTY_517",
  INSTALLMENT: "PROPERTY_519",
  TYPE: "PROPERTY_521",
  PERIOD: "PROPERTY_523",
};
const parcelas_agrupadas_list_map = {
  IBLOCK_ID: "41",
  DEAL: "PROPERTY_251",
  DATE: "PROPERTY_253",
  VALUE: "PROPERTY_255",
  PRODUCTS_ID: "PROPERTY_257", //MULTIPLO
  PRODUCTS_NAME: "PROPERTY_259", //MULTIPLO
  COMMENTS: "PROPERTY_261", //MULTIPLO
};
export {
  price_list_map,
  units_list_map,
  crm_deal_map,
  parcela_list_map,
  parcelas_agrupadas_list_map,
};
