import React from 'react';
import Router from './config/Router';
import 'antd/dist/antd.css';

import Bitrix24Provider from './providers/Bitrix24Provider';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

import './App.css';
import Bitrix24ListProvider from './providers/Bitrix24ListsProvider';

function App() {  
  return (
    <ConfigProvider locale={ptBR}>
      <Bitrix24Provider>
        <Bitrix24ListProvider>
          <Router />
        </Bitrix24ListProvider>
      </Bitrix24Provider>   
    </ConfigProvider>
  );
}

export default App;
