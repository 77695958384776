import React from "react";
import { Bitrix24Consumer } from "../contexts/Bitrix24Context";
import { Bitrix24ListsConsumer } from "../contexts/Bitrix24ListsContext";
import SelectProductPrice from "../components/SelectProductPrice";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import InputBRL from "../components/InputBRL";
import ModalAlert from "../components/Modal"


import {
  message,
  Table,
  Card,
  Form,
  Button,
  Select,
  Checkbox,
  Input,
} from "antd";
import {
  parcela_list_map,
  parcelas_agrupadas_list_map,
  units_list_map,
  crm_deal_map,
} from "../config/map";

const MEASURES = [
  {
    id_p: 9,
    id_pr: 796,
    name: "pcs.",
  },
];

const { Option } = Select;

class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.interval = "m_0";
    this.deal = {};
    this.state = {
      updated: false,
      products: [],
      initializing: true,
      error: false,
      myTable: {},
      deal: {},
      disabled: false,
      visible: false,
      //parcelas
      installment: {
        apply: false,
        configs: [
          {
            initialDate: new Date().toISOString().slice(0, 10),
            type: "", // share | repeat
            period: {
              type: "m",
              value: 1,
            },
            qtty: 1,
            installments: [],
          },
        ],
      },
    };
  }
  async componentDidMount() {
    try {
      let placement = this.props.bitrix.placement;

      /* TESTE DO PLACEMENT */
      if (placement.placement !== "CRM_DEAL_DETAIL_TAB") {
        placement.options = { ID: "28108" };
      }
      let deal_id = placement.options.ID;
      if (!deal_id) throw new Error("Salve o negocio antes");
      this.setState({ deal_id });
      let deal = await this.props.bitrix.getDeal(deal_id);

      this.deal = deal;

      this.setState({ deal });
      if (!deal[crm_deal_map.UNITY]) {
        throw new Error("Selecione e salve a unidade de negócio antes");
      }
      let list_units = await this.props.b24_lists.getListElems(units_list_map, {
        ID: deal[crm_deal_map.UNITY],
      });
      console.log(list_units);


      let unity = list_units[0];
      this.setState({ unity });
      console.log(unity);


      let product_rows = await this.props.bitrix.getProductRowsDeal(deal_id);
      console.log(product_rows);
      
      let deal_fields = await this.props.bitrix.getDealFields();

      await this.loadProductRows(product_rows);
      this.setState({ initializing: false });

      let savedInstallments = await this.getSavedInstallments();
      if (savedInstallments.length > 0 && 1) {
        await this.loadInstallments(savedInstallments);
      }
      window.BX24.fitWindow();
    } catch (e) {
      //console.log(e);
      let errorMessage = e.ex ? e.ex.error_description : e.message;
      this.setState({ error: true, initializing: false, errorMessage });
    }

  }

  async loadInstallments(savedInstallments) {
    let groupInstallment = this.groupBy(savedInstallments, "CONFIG");
    let installment = this.state.installment;
    installment.apply = true;
    //console.log(groupInstallment);
    for (let i = 0; i < groupInstallment.length; i++) {
      //console.log(groupInstallment[i]);
      let installments = groupInstallment[i].map((i) => {
        return {
          date: i.DATE.split("/").reverse().join("-"),
          value: i.VALUE,
          comment: i.COMMENT,
        };
      });
      const period_string = groupInstallment[i][0].PERIOD;
      const type = groupInstallment[i][0].TYPE;
      const products = Array.isArray(groupInstallment[i][0].PRODUCTS_ID)
        ? groupInstallment[i][0].PRODUCTS_ID
        : [groupInstallment[i][0].PRODUCTS_ID];

      installment.configs[i] = {
        initialDate: groupInstallment[i][0].DATE.split("/").reverse().join("-"),
        type: type, // share | repeat
        period: {
          type: period_string.split("_")[0],
          value: Number(period_string.split("_")[1]),
        },
        products: products,
        qtty: installments.length,
        installments: installments,
      };
    }
    //console.log(installment);
    this.setState({ installment });
    window.BX24.fitWindow();
  }
  async loadProductRows(productRows) {
    let products = productRows.map((pw) => {
      return {
        uid: "_" + Math.random().toString(36).substr(2, 9),
        QTD: pw.QUANTITY,
        NAME: pw.PRODUCT_NAME,
        PRICE: pw.PRICE_BRUTTO,
        DISCOUNT_RATE: pw.DISCOUNT_RATE,
        PRODUCT_ID: pw.PRODUCT_ID,
        DISCOUNT_SUM: pw.DISCOUNT_SUM,
        MEASURE_CODE: pw.MEASURE_CODE === 0 ? null : pw.MEASURE_CODE,
        TOTAL: pw.PRICE * pw.QUANTITY,
      };
    });
    this.setState({ products });
  }
  async receiveProductList(productsSelect) {
    let products = Object.assign([], this.state.products);

    products.push(...productsSelect);

    products.sort((a, b) => {
      if (a.NAME < b.NAME) {
        return -1;
      }
      if (a.NAME > b.NAME) {
        return 1;
      }
      return 0;
    });

    this.setState({ products, selectingProducts: false, updated: true });
    window.BX24.fitWindow();
  }
  changeProductQtd(r, e) {
    if (isNaN(e.target.value)) return;
    let products = Object.assign([], this.state.products);
    for (let i = 0; i < products.length; i++) {
      if (products[i].uid === r.uid) {
        let p = products[i];
        p.QTD = e.target.value;
        p.DISCOUNT_SUM = (p.DISCOUNT_RATE * p.PRICE * p.QTD) / 100;
        p.TOTAL = p.PRICE * p.QTD - p.DISCOUNT_SUM;
      }
    }
    this.setState({ products, updated: true });
  }
  getTotal(product_ids) {
    let products = Object.assign([], this.state.products);
    let total = 0;
    products = products.filter((p) =>
      product_ids.includes(p.PRODUCT_ID.toString())
    );
    total = products.reduce((a, b) => {
      return a + b.PRICE * b.QTD;
    }, 0);
    let discout_sum = 0;
    discout_sum = products.reduce((a, b) => {
      return a + (b.DISCOUNT_SUM ? b.DISCOUNT_SUM : 0);
    }, 0);
    return total - discout_sum;
  }
  CalcTotalOrc(props) {
    let products = Object.assign([], props.products);
    let installment = props.installment;
    let total = 0;
    let sum_installments = props.getSumInstallments();

    let products_in_installments = [];

    installment.configs.forEach((c) => {
      if (c.products) {
        products_in_installments.push(...c.products);
      }
    });
    //console.log("sum installments", sum_installments);
    //console.log("products in installment", products_in_installments);
    let total_products_not_in_installment = products.reduce((a, b) => {
      return (
        a +
        (!products_in_installments.includes(b.PRODUCT_ID.toString())
          ? b.PRICE * b.QTD
          : 0)
      );
    }, 0);
    //console.log("sum not in installment", total_products_not_in_installment);
    total = total_products_not_in_installment + sum_installments;
    let discout_sum = 0;
    discout_sum = products.reduce((a, b) => {
      return a + (b.DISCOUNT_SUM ? b.DISCOUNT_SUM : 0);
    }, 0);

    let currencyCode = props.currency ? props.currency : "BRL";
    return (
      <div
        style={{
          padding: 10,
          background: "#deebf1",
          float: "right",
          width: 230,
          marginTop: 10,
        }}
      >
        <table>
          <tbody>
            <tr>
              <th>Montante Bruto:</th>
              <td>
                {total.toLocaleString("pt-br", {
                  style: "currency",
                  currency: currencyCode,
                })}
              </td>
            </tr>
            <tr>
              <th>Desconto Total:</th>
              <td>
                {discout_sum.toLocaleString("pt-br", {
                  style: "currency",
                  currency: currencyCode,
                })}
              </td>
            </tr>
            <tr>
              <th>Montante Total:</th>
              <td>
                {(total - discout_sum).toLocaleString("pt-br", {
                  style: "currency",
                  currency: currencyCode,
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  /* É chamado ao salvar  */
  async updateDeal(e, del = 0) {
    console.log(e);
    this.onOpenModal();
    try {
      this.setState({ saving: true });
      //Criando um novo orçamento sempre que mudar o negócio
      await this.createQuote();
      let products = Object.assign([], this.state.products);
      let productRows = products.map((p) => {
        return {
          PRODUCT_NAME: p.NAME,
          PRODUCT_ID: p.PRODUCT_ID,
          //"DISCOUNT_SUM":p.DISCOUNT_SUM,
          DISCOUNT_RATE: p.DISCOUNT_RATE,
          DISCOUNT_TYPE_ID: 2,
          PRICE: p.TOTAL / p.QTD,
          PRICE_BRUTTO: p.PRICE,
          PRICE_NETTO: p.PRICE,
          PRICE_EXCLUSIVE: p.TOTAL / p.QTD,
          QUANTITY: p.QTD,
          //"MEASURE_NAME": p.MEASURE_NAME,
          //"MEASURE_CODE":p.MEASURE_CODE,
          //"CURRENCY_ID": "BRL"
        };
      });
      let price_pontual = products.reduce(
        (a, p) => a + (p.MEASURE_CODE === 1 ? p.PRICE : 0),
        0
      );
      //console.log("VALOR PONTUAL INICIAL", price_pontual);
      //let OPPORTUNITY = productRows.reduce((a,b) => {return a + (b.PRICE * b.QUANTITY)},0);

      let count = this.deal.UF_CRM_1664993787;
      if(typeof count === undefined || count == ''){
        count = 0;
      }else{
        count = count;
      }

      let dealUpdateFields = '';

      if(del == 1){
        dealUpdateFields = {
          CURRENCY_ID: "BRL",
          UF_CRM_1665404471 : "",
          UF_CRM_5E2A1B2812B1F : 0,
        };
      }else{
        dealUpdateFields = {
          CURRENCY_ID: "BRL",
          UF_CRM_1665404471 : "",
          UF_CRM_1664993787: parseInt(count) + 1
        };
      }

      dealUpdateFields[crm_deal_map.PONTUAL_INITIAL_VALUE] = price_pontual;
      await this.saveProductsOnDeal(
        this.state.deal_id,
        productRows,
        dealUpdateFields
      );
      message.success("Produtos Salvos no Negócio");
      if (this.state.installment.apply) {
        await this.saveInstallmentListDeal();
        message.success("Lista de parcelas criada");
      } else {
        this.removeInstallmentLists();
        this.removeGrupedInstallmentLists();
      }

      this.setState({ saving: false });
      this.onCloseModal();
    } catch (e) {
      this.setState({ saving: false });
    }
  }
  
  async createQuote() {
    try {
      this.setState({ creating_quote: true });
      let products = Object.assign([], this.state.products);

      let productRows = products.map((p) => {
        return {
          PRODUCT_NAME: p.NAME,
          PRODUCT_ID: p.PRODUCT_ID,
          //"DISCOUNT_SUM":p.DISCOUNT_SUM,
          DISCOUNT_RATE: p.DISCOUNT_RATE,
          DISCOUNT_TYPE_ID: 2,
          PRICE: p.TOTAL / p.QTD,
          PRICE_BRUTTO: p.PRICE,
          PRICE_NETTO: p.PRICE,
          PRICE_EXCLUSIVE: p.TOTAL / p.QTD,
          QUANTITY: p.QTD,
          //"MEASURE_NAME": p.MEASURE_NAME,
          //"MEASURE_CODE":p.MEASURE_CODE,
          //"CURRENCY_ID": "BRL"
        };
      });
      //let OPPORTUNITY = productRows.reduce((a,b) => {return a + (b.PRICE * b.QUANTITY)},0);
      let TITLE =
        this.state.deal.TITLE +
        " - Orçamento " +
        new Date().toLocaleDateString();

      let quote_fields = {
        TITLE: TITLE,
        ASSIGNED_BY_ID: this.state.deal.ASSIGNED_BY_ID,
        CURRENCY_ID: "BRL",
        DEAL_ID: this.state.deal.ID,
      };

      await this.createQuoteAndSaveProducts(productRows, quote_fields);
      message.success("Novo orçamento criado");
      this.setState({ creating_quote: false });
    } catch (e) {
      console.log(e);
      this.setState({ creating_quote: false });
    }
  }
  saveProductsOnDeal(dealId, productRows, dealUpdateFields) {
    return new Promise((resolve, reject) => {
      this.props.bitrix
        .setProductRowsDeal(dealId, productRows)
        .then((result_rows) => {
          this.props.bitrix
            .editDeal(dealId, dealUpdateFields)
            .then((result) => {
              resolve(result_rows);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  createQuoteAndSaveProducts(productRows, quote_fields) {
    return new Promise((resolve, reject) => {
      this.props.bitrix
        .addQuote(quote_fields)
        .then((quote_id) => {
          this.props.bitrix
            .setProductRowsQuote(quote_id, productRows)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  removeItem(uid) {
    let products = Object.assign([], this.state.products);
    products = products.filter((p) => {
      return p.uid !== uid;
    });
    this.setState({ products });
  }
  editProductoValue(uid) {
    var newPrice = (function ask() {
      var n = prompt("Novo valor para o produto");
      return isNaN(n.replace(",", ".")) ? ask() : n.replace(",", ".");
    })();
    let products = Object.assign([], this.state.products);

    for (let i = 0; i < products.length; i++) {
      if (products[i].uid === uid) {
        products[i].PRICE = newPrice;
        products[i].DISCOUNT_SUM =
          (products[i].DISCOUNT_RATE * products[i].PRICE * products[i].QTD) /
          100;
        products[i].TOTAL =
          products[i].PRICE * products[i].QTD - products[i].DISCOUNT_SUM;
        break;
      }
    }
    this.setState({ products });
  }
  stringToBRL(str) {
    return Number(str).toLocaleString("pt-br", { minimumFractionDigits: 2 });
  }
  changeMeasure(r, id) {
    let products = Object.assign([], this.state.products);
    for (let i = 0; i < products.length; i++) {
      if (products[i].uid === r.uid) {
        products[i].MEASURE_CODE = id;
        products[i].MEASURE_NAME = MEASURES.filter((m) => {
          return m.id_pr === id;
        })[0].name;
      }
    }
    this.setState({ products, updated: true });
  }
  changeDiscountSum(r, event) {
    let sum = event.target.value;
    let products = Object.assign([], this.state.products);
    for (let i = 0; i < products.length; i++) {
      if (products[i].uid === r.uid) {
        let p = products[i];
        p.DISCOUNT_SUM = sum;
        p.TOTAL = p.PRICE * p.QTD - p.DISCOUNT_SUM;
        p.DISCOUNT_RATE = (p.DISCOUNT_SUM * 100) / (p.PRICE * p.QTD);
        products[i] = p;
        break;
      }
    }
    this.setState({ products, updated: true });
  }
  changeDiscountRate(r, event) {
    let rate = event.target.value;
    let products = Object.assign([], this.state.products);
    for (let i = 0; i < products.length; i++) {
      if (products[i].uid === r.uid) {
        let p = products[i];
        p.DISCOUNT_RATE = rate;
        p.DISCOUNT_SUM = (rate * p.PRICE * p.QTD) / 100;
        p.TOTAL = p.PRICE * p.QTD - p.DISCOUNT_SUM;
        products[i] = p;
        break;
      }
    }
    this.setState({ products, updated: true });
  }
  async sincronizar() {
    this.setState({ sincronizando: true });
    let quotes = await this.props.bitrix.listQuotesFromDeal(this.state.deal.ID);
    let last_aproved_quote = null;

    for (let i = 0; i < quotes.length; i++) {
      if (quotes[i].STATUS_ID === "APPROVED") {
        last_aproved_quote = quotes[i];
        break;
      }
    }

    if (!last_aproved_quote) {
      message.error("Nenhum dos orçamentos desse Negócio foi aprovado");
      return;
    }
    let product_rows = await this.props.bitrix.getProductRowsQuote(
      last_aproved_quote.ID
    );

    let result = await this.props.bitrix.setProductRowsDeal(
      this.state.deal.ID,
      product_rows
    );
    message.success(
      "Produtos do negócio sincronizado com o Orçamento " +
        last_aproved_quote.TITLE +
        " ID: " +
        last_aproved_quote.ID
    );

    for (let i = 0; i < quotes.length; i++) {
      if (quotes[i].ID !== last_aproved_quote.ID) {
        await this.props.bitrix.editQuote(quotes[i].ID, {
          STATUS_ID: "DECLAINED",
        });
      }
    }
    message.success("Orçamentos restantes recusados");

    setTimeout(() => {
      message.info("recarregando...");
      setTimeout(() => {
        window.location = "/placement_deal";
      }, 3000);
    }, 500);

    this.setState({ sincronizando: false });
  }
  /* Parcelamento */
  setInstallmentType(config_index, type) {
    let installment = this.state.installment;
    installment.configs[config_index].type = type;

    this.setState({ installment });
  }
  setInstallment() {
    let installment = this.state.installment;
    installment.apply = !installment.apply;
    //reseta todas as configuraçõe
    installment.configs = [
      {
        initialDate: new Date().toISOString().slice(0, 10),
        products: [],
        type: "",
        installments: [],
        qtty: 1,
        period: {
          type: "m",
          value: 0,
        },
      },
    ];
    this.setState({ installment });
  }
  setInstallmentQtty(config_index) {
    this.setDisabled(true);
    let installment = this.state.installment;
    let qtty = installment.configs[config_index].qtty;
    //installment.qtty = qtty;
    installment.configs[config_index].installments = [];
    //console.log(this.getTotal());

    let installment_value = 0;
    if (installment.configs[config_index].type === "share") {
      //console.log(installment.type)
      installment_value =
        this.getTotal(installment.configs[config_index].products) / qtty;
    } else if (installment.configs[config_index].type === "repeat") {
      //console.log(installment.type)
      installment_value = this.getTotal(
        installment.configs[config_index].products
      );
    }

    for (let i = 0; i < qtty; i++) {
      installment.configs[config_index].installments.push({
        date: "",
        value: installment_value,
        comment: " ",
      });
    }
    //console.log(installment);
    this.setInstallmentDates(config_index,this.interval);
    this.setState({ installment });
  }
  changeInstallmentQtty(config_index, e) {
    let value = e.target.value;
    let installment = this.state.installment;
    installment.configs[config_index].qtty = value;
    this.setState({ installment });
  }
  setInstallmentDates(config_index, interval) {
    let interval_type = interval.split("_")[0];
    let interval_value = Number(interval.split("_")[1]);
    this.interval = interval;
    //console.log(interval_type, interval_value);
    let installment = this.state.installment;

    installment.configs[config_index].period = {
      type: interval_type,
      value: interval_value,
    };

    let initialDate = new Date(installment.configs[config_index].initialDate);

    installment.configs[config_index].installments[0].date = new Date(
      initialDate
    );

    //console.log(installment.installments[0].date.toISOString());

    for (let i = 1; i < installment.configs[config_index].qtty; i++) {
      let new_date = null;

      if (interval_type === "d") {
        //console.log("dia");
        new_date = new Date(
          installment.configs[config_index].installments[i - 1].date
        ).setDate(
          new Date(
            installment.configs[config_index].installments[i - 1].date
          ).getDate() + interval_value
        );
      } else if (interval_type === "m") {
        //console.log("mes");
        new_date = new Date(
          installment.configs[config_index].installments[i - 1].date
        ).setMonth(
          new Date(
            installment.configs[config_index].installments[i - 1].date
          ).getMonth() + interval_value
        );
      } else if (interval_type === "a") {
        //console.log("ano");
        new_date = new Date(
          installment.configs[config_index].installments[i - 1].date
        ).setFullYear(
          new Date(
            installment.configs[config_index].installments[i - 1].date
          ).getFullYear() + interval_value
        );
      }
      //console.log(i,new Date(new_date).toISOString());
      installment.configs[config_index].installments[i].date = new Date(
        new_date
      );
    }
    for (let i = 0; i < installment.configs[config_index].qtty; i++) {
      //console.log(i, installment.installments[i].date.toISOString() );
      installment.configs[config_index].installments[i].comment = "";
      installment.configs[config_index].installments[
        i
      ].date = installment.configs[config_index].installments[i].date
        .toISOString()
        .substring(0, 10);
    }

    //console.log(installment);
    this.setState({ installment });
  }
  changeInstallmentDate(config_index, index, e) {
    let installment = this.state.installment;
    installment.configs[config_index].installments[index].date = e.target.value;
    this.setState({ installment });
  }
  changeInstallmentComment(config_index, index, e) {
    //console.log("COMMENT CONFIG: ", config_index);
    ////console.log("COMMENT PARCEL: ", index);

    let installment = this.state.installment;
    installment.configs[config_index].installments[index].comment =
      e.target.value;
    this.setState({ installment });
  }
  changeInstallmentValue(config_index, index, e) {
    //console.log("config_index", config_index);
    //console.log("installment_index", index);
    //console.log(e, index);
    let installment = this.state.installment;
    installment.configs[config_index].installments[index].value =
      e.target.value;

    let total_here = 0;
    for (let i = 0; i <= index; i++) {
      total_here += Number(
        installment.configs[config_index].installments[i].value
      );
    }
    //console.log("total até o input" ,total_here);

    let total_to_end = installment.configs[config_index].qtty - (index + 1);
    //console.log("qtd parcelas restantes" , total_to_end);

    let getTotal = 0;
    if (installment.configs[config_index].type === "share") {
      //console.log(installment.type)
      getTotal = this.getTotal(installment.configs[config_index].products);
    } else if (installment.configs[config_index].type === "repeat") {
      //console.log(installment.type)
      getTotal =
        this.getTotal(installment.configs[config_index].products) *
        installment.configs[config_index].qtty;
    }

    if (index !== installment.configs[config_index].qtty - 1) {
      for (let i = index + 1; i < installment.configs[config_index].qtty; i++) {
        //console.log(i);
        installment.configs[config_index].installments[i].value =
          (getTotal - total_here) / total_to_end;
      }
    }
    this.setState({ installment });
  }
  setProductInstallmentConfig(config_index, e) {
    //console.log(e.target.checked);
    //console.log(e.target.value);

    let installment = this.state.installment;
    if (!installment.configs[config_index].products) {
      installment.configs[config_index].products = [];
    }
    if (e.target.checked) {
      installment.configs[config_index].products.push(e.target.value);
    } else {
      installment.configs[config_index].products = installment.configs[
        config_index
      ].products.filter((id) => id !== e.target.value);
    }
    this.setState({ installment });
  }
  async getSavedInstallments() {
    let list_filter = {};
    list_filter[parcela_list_map.DEAL] = this.state.deal.ID;
    let saved_installments = await this.props.b24_lists.getListElems(
      parcela_list_map,
      list_filter
    );
    return saved_installments;
  }
  removeInstallmentLists() {
    return new Promise(async (resolve, reject) => {
      let list_filter = {};
      list_filter[parcela_list_map.DEAL] = this.state.deal.ID;
      let saved_installments = await this.props.b24_lists.getListElems(
        parcela_list_map,
        list_filter
      );
      for (let i = 0; i < saved_installments.length; i++) {
        await this.props.b24_lists.removeListElem(
          parcela_list_map,
          saved_installments[i].ID
        );
      }
      resolve();
    });
  }
  saveInstallmentListDeal() {
    return new Promise(async (resolve, reject) => {
      await this.removeInstallmentLists();

      let total_value_installments = 0;
      let products_in_installments = [];
      let rows = [];
      this.state.installment.configs.map((config, config_index) => {
        let config_rows = config.installments.map((i, installment_index) => {
          total_value_installments += Number(i.value);
          products_in_installments.push(...config.products);
          return {
            NAME: i.date + "|" + i.value,
            ELEMENT_CODE: this.state.deal.ID + "|" + i.date + "|" + i.value,
            DEAL: this.state.deal.ID,
            DATE: i.date,
            VALUE: i.value,
            COMMENT: i.comment,
            PRODUCTS_ID: config.products,
            PRODUCTS_NAME: config.products.map((pid) => {
              return this.state.products.filter(
                (p) => Number(p.PRODUCT_ID) === Number(pid)
              )[0].NAME;
            }),
            CONFIG: config_index,
            INSTALLMENT: installment_index,
            PERIOD: config.period.type + "_" + config.period.value,
            TYPE: config.type,
          };
        });
        rows.push(...config_rows);
      });

      //console.log("linhas", rows);
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        await this.props.b24_lists.saveListElem(parcela_list_map, row);
      }
      let grouped_product_rows = await this.saveGroupedInstallments(rows);

      let total_products_not_in_installment = this.state.products.reduce(
        (a, b) => {
          return (
            a +
            (!products_in_installments.includes(b.PRODUCT_ID.toString())
              ? b.PRICE * b.QTD
              : 0)
          );
        },
        0
      );

      let deal_fields = {
        OPPORTUNITY:
          total_value_installments + total_products_not_in_installment,
      };
      deal_fields[crm_deal_map.QTTY_INSTALLMENTS] = grouped_product_rows.length;
      deal_fields[crm_deal_map.PRICE_INSTALLMENT] =
        grouped_product_rows.length > 0 ? grouped_product_rows[0].VALUE : 0;

      await this.props.bitrix.editDeal(this.state.deal.ID, deal_fields);

      resolve();
    });
  }
  removeGrupedInstallmentLists() {
    return new Promise(async (resolve, reject) => {
      let list_filter = {};
      list_filter[parcelas_agrupadas_list_map.DEAL] = this.state.deal.ID;
      let saved_installments = await this.props.b24_lists.getListElems(
        parcelas_agrupadas_list_map,
        list_filter
      );
      for (let i = 0; i < saved_installments.length; i++) {
        await this.props.b24_lists.removeListElem(
          parcelas_agrupadas_list_map,
          saved_installments[i].ID
        );
      }
      resolve();
    });
  }
  async saveGroupedInstallments(savedIntallmentsRows) {
    return new Promise(async (resolve, reject) => {
      //AGRUPANDO PARCELAS
      let gruped_installments = this.groupBy(savedIntallmentsRows, "DATE");

      //REMOVENDO REGISTRO PARA ESSE NEGOCIO
      await this.removeGrupedInstallmentLists();
      //MONTANDO ELEMNTOS PARA SALVAR NA LISTA;
      let rows = gruped_installments.map((grouped) => {
        let sumValue = grouped.reduce(
          (sum, elem) => sum + Number(elem.VALUE),
          0
        );
        let comments = grouped.map((g) => g.COMMENT);
        let products_ids = [];
        let products_name = [];
        grouped.forEach((g) => {
          products_ids.push(...g.PRODUCTS_ID);
          products_name.push(...g.PRODUCTS_NAME);
        });
        return {
          NAME: grouped[0].DATE + "|" + sumValue,
          ELEMENT_CODE:
            this.state.deal.ID + "|" + grouped[0].DATE + "|" + sumValue,
          DEAL: this.state.deal.ID,
          DATE: grouped[0].DATE,
          VALUE: sumValue,
          PRODUCTS_ID: products_ids, //MULTIPLO
          PRODUCTS_NAME: products_name, //MULTIPLO
          COMMENTS: comments, //MULTIPLO
        };
      });
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        await this.props.b24_lists.saveListElem(
          parcelas_agrupadas_list_map,
          row
        );
      }

      resolve(rows);
    });
  }
  groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }
  setInitialDate(config_index, e) {
    let initialDate = e.target.value;
    let installment = this.state.installment;
    installment.configs[config_index].initialDate = initialDate;
    this.setState({ installment });
  }
  addInstallmentConfig() {
    let installment = this.state.installment;
    installment.configs.push({
      initialDate: new Date().toISOString().slice(0, 10),
      type: "", // share | repeat
      period: {
        type: "m",
        value: 0,
      },
      qtty: 1,
      installments: [],
    });
    this.setState({ installment });
    window.BX24.fitWindow();
  }
  isInAnotherInstallmentConfig(config_index, product_id) {
    //console.log("PRODUCT CHECK, CONFIG INDEX ", config_index);
    //console.log("PRODUCT CHECK, PRODUCT ID ", product_id);
    let installment = this.state.installment;
    for (let i = 0; i < installment.configs.length; i++) {
      if (
        i !== config_index &&
        installment.configs[i].products &&
        installment.configs[i].products.includes(product_id.toString())
      ) {
        return true;
      }
    }
    return false;
  }
  allProductsInOneInstallmentConfig() {
    let installment_configs = this.state.installment.configs;
    let products = this.state.products;
    for (let p_index = 0; p_index < products.length; p_index++) {
      let isset = false;
      for (let c_index = 0; c_index < installment_configs.length; c_index++) {
        if (
          installment_configs[c_index].products &&
          installment_configs[c_index].products.includes(
            products[p_index].PRODUCT_ID.toString()
          )
        ) {
          isset = true;
          break;
        }
      }
      if (!isset) {
        //console.log(
        //  "UM  PRODUTO NÂO SETADO = ID = " + products[p_index].PRODUCT_ID
        //);
        return false;
      }
    }
    //console.log("TODOS PRODUTOS SETADOS");
    return true;
  }
  removeInstallmentConfig(config_index) {
    let installment = this.state.installment;
    installment.configs = installment.configs.filter(
      (c, i) => i !== config_index
    );
    if (installment.configs.length === 0) {
      installment.apply = false;
    }
    this.setState({ installment });
    this.setDisabled(false);
    this.updateDeal(this,1);
    // this.updateDeal(this);
  }
  calcProductPriceFromInstallments(config) {
    let totalInstallments = config.installments.reduce(
      (a, i) => a + Number(i.value),
      0
    );
    let type = config.type;
    if (type === "repeat") {
      totalInstallments = totalInstallments / config.qtty;
    }
    let totalProductsBefore = this.state.products.reduce((a, p) => {
      let price = 0;
      if (config.products.includes(p.PRODUCT_ID.toString())) {
        price = Number(p.PRICE) * Number(p.QTD);
      }
      return a + price;
    }, 0);
    //console.log(totalProductsBefore, totalInstallments);

    let products = this.state.products;

    for (let i = 0; i < products.length; i++) {
      if (config.products.includes(products[i].PRODUCT_ID.toString())) {
        let p_total_without_discount =
          Number(products[i].PRICE) * Number(products[i].QTD);
        let p_new_total =
          (p_total_without_discount * totalInstallments) / totalProductsBefore;
        let p_new_price = p_new_total / Number(products[i].QTD);
        products[i].PRICE = p_new_price;
        products[i].DISCOUNT_SUM =
          (products[i].DISCOUNT_RATE * products[i].PRICE * products[i].QTD) /
          100;
        products[i].TOTAL =
          products[i].PRICE * products[i].QTD - products[i].DISCOUNT_SUM;
      }
    }
    this.setState({ products });
  }
  TotalSumInstallments(props) {
    let config = props.config;
    //console.log("CONFIG : ", config);
    if (!config.installments) return null;
    let sum = config.installments.reduce((a, i) => a + Number(i.value), 0);
    return (
      <div style={{ background: "yellow" }}>
        Soma das parcelas:{" "}
        {sum.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}
      </div>
    );
  }
  getTotalSumAllInstallments() {
    let installment = this.state.installment;
    let sum = installment.configs.reduce((a, config) => {
      return (
        a + Number(config.installments.reduce((a, i) => a + Number(i.value), 0))
      );
    }, 0);
    return sum;
  }

  setDisabled = (disable) => {
    this.setState({ disabled: disable });
  }
  
  onOpenModal = () => {
    console.log(this.state.visible);
    console.log("entrou para abrir modal");
    this.setState({ visible: true });
  }
  
  onCloseModal = () => {
    console.log(this.state.visible);
    console.log("entrou para fechar modal");
    this.setState({ visible: false });
  }

  render() {
    if (this.state.initializing) {
      return <div>Aguarde...</div>;
    } else if (this.state.error) {
      return (
        <div>
          Não foi possivel iniciar a tabela de preços.
          <br />
          Detalhes: {this.state.errorMessage}
        </div>
      );
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <ModalAlert
            title={'Atenção'} 
            visible={this.state.visible}
          />
          <Card
            id="product"
            size="small"
            title="Produtos no Orçamento: "
            extra={
              this.state.unity
                ? "Unidade: " + this.state.unity.NAME
                : "carregado.."
            }
            headStyle={{ background: "#fafafa" }}
          >
            <Button
              disabled={this.state.installment.apply}
              href="#product"
              onClick={(e) => {
                this.setState({ selectingProducts: true });
              }}
            >
              Selecione os produtos
            </Button>{" "}
            <Button onClick={() => (window.location = "/placement_deal")}>
              Atualizar lista de produtos
            </Button>{" "}
            {/*}
            <Button
              style={{ float: "right" }}
              type="primary"
              onClick={this.sincronizar.bind(this)}
              loading={this.state.sincronizando}
              title="Está ação buscará o ultimo orçamento ganho e salvará seus produtos neste negócio"
            >
              {this.state.sincronizando ? "Sincronizando..." : "Sincronizar"}
            </Button>
            */}
            <div style={{ paddingTop: 5 }}>
              {this.state.selectingProducts ? (
                <SelectProductPrice
                  disabled={this.state.installment.apply}
                  onConfirm={this.receiveProductList.bind(this)}
                  unity={this.state.unity.ID}
                />
              ) : null}
            </div>
            {this.state.products.length > 0 ? (
              <div>
                <Table
                  size="small"
                  rowClassName={(r, index) => {
                    return !r.PRODUCT_ID || r.PRODUCT_ID === 0
                      ? "error_row"
                      : "";
                  }}
                  rowKey={(r) => r.uid}
                  columns={[
                    {
                      title: "PROD_ID",
                      dataIndex: "PRODUCT_ID",
                      key: "PRODUCT_ID",
                      render: (text) => <div>{text}</div>,
                    },
                    {
                      title: "Nome",
                      dataIndex: "NAME",
                      width: "35%",
                      key: "NAME",
                    },
                    {
                      title: "Preço",
                      dataIndex: "PRICE",
                      key: "PRICE",
                      render: (val) => this.stringToBRL(val),
                    },
                    {
                      title: "Desconto Valor",
                      dataIndex: "DISCOUNT_SUM",
                      width: "15%",
                      key: "DISCOUNT_SUM",
                      render: (sum, r) => (
                        <div>
                          <InputBRL
                            disabled={this.state.installment.apply}
                            suffix="$"
                            onChange={this.changeDiscountSum.bind(this, r)}
                            value={sum}
                          />
                        </div>
                      ),
                    },
                    {
                      title: "Desconto Taxa",
                      dataIndex: "DISCOUNT_RATE",
                      width: "10%",
                      key: "DISCOUNT_RATE",
                      render: (rate, r) => (
                        <InputBRL
                          disabled={this.state.installment.apply}
                          suffix="%"
                          onChange={this.changeDiscountRate.bind(this, r)}
                          value={rate}
                        />
                      ),
                    },
                    {
                      title: "Quantidade",
                      dataIndex: "QTD",
                      key: "QTD",
                      render: (qtd, r) => (
                        <Input
                          type="number"
                          disabled={this.state.installment.apply}
                          onChange={this.changeProductQtd.bind(this, r)}
                          value={qtd}
                        />
                      ),
                    },
                    {
                      title: "Total",
                      dataIndex: "TOTAL",
                      key: "TOTAL",
                      render: (val, r) => this.stringToBRL(val),
                    },
                    {
                      title: "",
                      dataIndex: "",
                      key: "",
                      render: (val, r) => (
                        <div>
                          <Button
                            disabled={this.state.installment.apply}
                            size="small"
                            onClick={this.removeItem.bind(this, r.uid)}
                            type=""
                            shape="circle"
                            icon="close"
                          />{" "}
                          <Button
                            disabled={this.state.installment.apply}
                            size="small"
                            onClick={this.editProductoValue.bind(this, r.uid)}
                            type=""
                            shape="circle"
                            icon="edit"
                          />
                        </div>
                      ),
                    },
                  ]}
                  dataSource={this.state.products}
                />
              </div>
            ) : (
              "Nenhum produto selecionado."
            )}
            <br />
            <Checkbox
              onChange={this.setInstallment.bind(this)}
              checked={this.state.installment.apply}
            >
              Pagamento Parcelado
            </Checkbox>
            {this.state.installment.apply && (
              <div>
                {this.state.installment.configs.length > 0 &&
                  this.state.installment.configs.map((config, config_index) => (
                    <Card
                      key={config_index}
                      style={{ marginTop: 10 }}
                      size="small"
                      title={"Parcelamento " + (config_index + 1)}
                      extra={
                        <Button
                          onClick={
                            this.removeInstallmentConfig.bind(
                              this,
                              config_index
                            )
                          }
                          type="danger"
                        >
                          X
                        </Button>
                      }
                    >
                      Parcela Inicial <br />
                      <input
                        disabled={config.products && config.products.length > 0}
                        type="date"
                        onChange={this.setInitialDate.bind(this, config_index)}
                        value={config.initialDate}
                      />
                      <br />
                      ID dos Produtos neste parcelamento
                      <br />
                      {this.state.products.map((p) => {
                        let notshow = this.isInAnotherInstallmentConfig.bind(
                          this,
                          config_index,
                          p.PRODUCT_ID
                        )();
                        //console.log(notshow);
                        if (notshow) {
                          return null;
                        }
                        return (
                          <Checkbox
                            key={p.PRODUCT_ID}
                            value={p.PRODUCT_ID}
                            checked={
                              config.products &&
                              config.products.includes(p.PRODUCT_ID.toString())
                            }
                            disabled={
                              config.type !== "" || config.initialDate === ""
                            }
                            onClick={this.setProductInstallmentConfig.bind(
                              this,
                              config_index
                            )}
                          >
                            {p.PRODUCT_ID}
                          </Checkbox>
                        );
                      })}
                      <br />
                      Tipo de agenda
                      <Select
                        disabled={
                          !config.products ||
                          config.products.length === 0 ||
                          config.installments.length > 0
                        }
                        value={config.type}
                        onChange={this.setInstallmentType.bind(
                          this,
                          config_index
                        )}
                      >
                        <Option value={"share"}>
                          Dividir Quantia em varias parcelas
                        </Option>
                        <Option value={"repeat"}>
                          Repetir Quantia em todas as parcelas
                        </Option>
                      </Select>
                      Quantidade de parcelas:
                      <br />
                      <input
                        disabled={config.type === ""}
                        value={config.qtty}
                        onChange={this.changeInstallmentQtty.bind(
                          this,
                          config_index
                        )}
                      />
                      <Button
                        disabled={this.state.disabled || config.type === ""}
                        onClick={this.setInstallmentQtty.bind(
                          this,
                          config_index
                        )}
                      >
                        Ok
                      </Button>
                      <br />
                      Periodo das prestações
                      <Select
                        disabled={
                          config.type === "" ||
                          config.qtty === 0 ||
                          config.installments.length === 0
                        }
                        value={config.period.type + "_" + config.period.value}
                        onChange={this.setInstallmentDates.bind(
                          this,
                          config_index
                        )}
                      >
                        <Option value={"m_0"}></Option>
                        <Option value={"d_1"}>Diário</Option>
                        <Option value={"d_7"}>Semanal</Option>
                        <Option value={"m_1"}>Mensal</Option>
                        <Option value={"m_3"}>Trimestal</Option>
                        <Option value={"a_1"}>Anual</Option>
                      </Select>
                      {this.state.installment.configs[config_index].installments
                        .length > 0 &&
                        this.state.installment.configs[config_index].period
                          .value > 0 &&
                        this.state.installment.configs[config_index].period
                          .type !== "" && (
                          <div>
                            Parcelas:
                            <div style={{ display: "flex" }}>
                              <b style={{ flex: 1 }}>Data</b>
                              <b style={{ flex: 2 }}>Valor</b>
                              <b style={{ flex: 2 }}>Comentário</b>
                            </div>
                            {this.state.installment.configs[
                              config_index
                            ].installments.map((i, index) => (
                              <div key={index} style={{ display: "flex" }}>
                                <input
                                  style={{ flex: 1 }}
                                  onChange={this.changeInstallmentDate.bind(
                                    this,
                                    config_index,
                                    index
                                  )}
                                  type="date"
                                  value={i.date}
                                />
                                <input
                                  style={{ flex: 2 }}
                                  onChange={this.changeInstallmentValue.bind(
                                    this,
                                    config_index,
                                    index
                                  )}
                                  type="number"
                                  value={i.value}
                                />
                                <textarea
                                  style={{ flex: 2, height: 40 }}
                                  onChange={this.changeInstallmentComment.bind(
                                    this,
                                    config_index,
                                    index
                                  )}
                                  type="text"
                                  value={i.comment}
                                />
                              </div>
                            ))}
                            <div>
                              <this.TotalSumInstallments config={config} />
                              <br />
                              <Button
                                onClick={this.calcProductPriceFromInstallments.bind(
                                  this,
                                  config
                                )}
                              >
                                Recalcular preço produto
                              </Button>
                            </div>
                          </div>
                        )}
                    </Card>
                  ))}

                <Button
                  style={{ marginTop: 5 }}
                  disabled={this.allProductsInOneInstallmentConfig.bind(this)()}
                  onClick={this.addInstallmentConfig.bind(this)}
                >
                  Adicionar Parcelamento de Produto
                </Button>
              </div>
            )}
            {/*
            <pre style={{ display: "block" }}>
              {JSON.stringify(this.state.installment, null, "\t")}
            </pre>
            */}
          </Card>

          <this.CalcTotalOrc
            products={this.state.products}
            installment={this.state.installment}
            getSumInstallments={this.getTotalSumAllInstallments.bind(this)}
            currency={this.state.myTable.CURRENCY_ID}
          />
          <div style={{ marginTop: 10 }}>
            {/*
            <Button
              type="primary"
              size="large"
              onClick={this.createQuote.bind(this)}
              loading={this.state.saving}
            >
              {this.state.creating_quote
                ? "Criando..."
                : "Criar novo orçamento"}
            </Button>{" "}
            */}
            <Button
              type="primary"
              size="large"
              onClick={this.updateDeal.bind(this)} 
              loading={this.state.saving}
            >
              {this.state.saving
                ? "Salvando..."
                : "Atualizar Negócio e Orçamento"}
            </Button>{" "}
          </div>
        </Form>
      </div>
    );
  }
}

export default Bitrix24Consumer(Bitrix24ListsConsumer(Quote));
